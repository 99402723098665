import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@mui/material";

const styles = (_theme) => ({
  buttonUpload: {
    width: "100%",
  },
});

export class StudentPhoto extends Component {
  state = {
    doneCrop: false,
    src: null,
    croppedImageUrl: null,
    crop: {
      x: 10,
      y: 10,
      width: 150,
      height: 200,
      aspect: 3 / 4,
    },
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
        reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
    this.props.setOnCroping(true);
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, _percentCrop) => {
    this.setState({ crop });
  };

  onDoneCropPhoto = () => {
    const newPhoto = this.state.croppedImageUrl;
    this.setState({ doneCrop: true });
    this.props.setCropPhoto(newPhoto);
    this.props.setOnCroping(false);
  };

  onRemovePhoto = () => {
    this.setState({ doneCrop: false, src: null, croppedImageUrl: null });
    this.props.setOnCroping(false);
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      ).then(response => {
        this.setState({ croppedImageUrl: response });
      }).catch(error => {
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  uploadButton = () => (
    <div>
      <input
        hidden
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={this.onSelectFile}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="outlined"
          size="large"
          color="primary"
          component="span"
          className={this.props.classes.buttonUpload}
          startIcon={<PhotoCameraIcon />}
        >
          Selecione sua foto
        </Button>
      </label>
    </div>
  );

  clearButton = () => (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        component="span"
        className={this.props.classes.buttonUpload}
        startIcon={<DeleteIcon />}
        onClick={this.onRemovePhoto}
      >
        Remover foto
      </Button>
    </div>
  );

  NoPhotoDiv = () => (
    <Box fullWidth display="flex" alignItems="center" flexDirection="column">
      <AccountBoxIcon
        style={{ flex: 1, height: "100%", color: "#a2b9bc", fontSize: "300px" }}
      />
      {this.uploadButton()}
    </Box>
  );

  render() {
    const croppedImageUrl = this.state.croppedImageUrl;
    const onDone = this.state.doneCrop;

    return (
      <>
        <div>{!this.state.src && this.NoPhotoDiv()}</div>
        {this.state.src && !onDone && (
          <>
            <div>
              <ReactCrop
                src={this.state.src}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </div>
            <Box width="100%" display="flex" justifyContent="space-evenly">
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={this.onRemovePhoto}
                startIcon={<CancelIcon />}
              >
                Remover
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={this.onDoneCropPhoto}
                startIcon={<CheckCircleIcon />}
              >
                Concluido
              </Button>
            </Box>
          </>
        )}
        {onDone && !croppedImageUrl && (
          <Alert severity="error">
            Erro ao carregar a imagem, tente novamente, se o erro persistir tente um dispositivo diferente ou entre em contato!
          </Alert>
        )}
        {croppedImageUrl && onDone && (
          <div className="container-img-cropped">
            <img alt="Crop" src={croppedImageUrl} />
            {this.clearButton()}
          </div>
        )}
      </>
    );
  }
}
export default withStyles(styles)(StudentPhoto);
