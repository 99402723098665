import React, { useState } from "react";
import * as Yup from "yup";
import cep from "cep-promise";
import maskFunctions from "../../../utils/maskFunctions";

import { Formik } from "formik";
import { Box, TextField, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const StepEndereco = (props) => {
  useStyles();

  const nextStep = props.nextStep;
  const buttons = props.buttons;
  const setData = props.setData;
  const data = props.data;

  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);

  const [values, setValues] = useState({
    rua: "",
    numero: "",
    complemento: "",
    cep: "",
    cidade: "",
    uf: "",
    bairro: "",
  });

  async function onFetchCep(c, values) {
    if (c.length < 9) return;
    cep(c)
      .then((response) => {
        const v = {};
        v.cidade = response.city;
        v.uf = response.state;
        v.rua = response.street;
        v.bairro = response.neighborhood;
        v.cep = c;
        setValues({ ...values, ...v });
      })
      .catch(() => {
        setNotFound(true);
        setError(true);
      });
  }

  return (
    <Formik
      initialValues={{ ...values, ...data }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        rua: Yup.string(),
        numero: Yup.string().required("Digite o número da sua residência"),
        complemento: Yup.string(),
        cep: Yup.string().required("Digite o CEP"),
        cidade: Yup.string(),
        bairro: Yup.string(),
        uf: Yup.string(),
      })}
      onSubmit={(values) => {
        setData(values);
        nextStep();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <>
          <header>
            <div>
              <Typography color="textPrimary" variant="h2">
                Endereço
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Insira seu endereço
              </Typography>
            </div>
          </header>
          <main>
            <form onSubmit={handleSubmit}>
              <div className="pages-header">
                <Box mb={3}></Box>
              </div>
              <div className="pages-inside">
                <TextField
                  error={Boolean((touched.cep && errors.cep) || error)}
                  fullWidth
                  helperText={
                    (touched.cep && errors.cep) ||
                    (error &&
                      "Cep não encontrado, digite o endereço manualmente")
                  }
                  label="CEP"
                  margin="normal"
                  name="cep"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    maskFunctions.applyPattern(event, handleChange, "cep");
                    onFetchCep(event.target.value, values);
                  }}
                  value={values.cep}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.rua && errors.rua)}
                  fullWidth
                  helperText={touched.rua && errors.rua}
                  label="Rua"
                  margin="normal"
                  name="rua"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.rua}
                  variant="outlined"
                  disabled={!notFound}
                />
                <TextField
                  error={Boolean(touched.numero && errors.numero)}
                  fullWidth
                  helperText={touched.numero && errors.numero}
                  label="Numero"
                  margin="normal"
                  name="numero"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.numero}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.complemento && errors.complemento)}
                  fullWidth
                  helperText={touched.complemento && errors.complemento}
                  label="Complemento"
                  margin="normal"
                  name="complemento"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.complemento}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.bairro && errors.bairro)}
                  fullWidth
                  helperText={touched.bairro && errors.bairro}
                  label="Bairro"
                  margin="normal"
                  name="bairro"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bairro}
                  variant="outlined"
                  disabled={!notFound}
                />
                <TextField
                  error={Boolean(touched.cidade && errors.cidade)}
                  fullWidth
                  helperText={touched.cidade && errors.cidade}
                  label="Cidade"
                  margin="normal"
                  name="cidade"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cidade}
                  variant="outlined"
                  disabled={!notFound}
                />
                <TextField
                  error={Boolean(touched.uf && errors.uf)}
                  fullWidth
                  helperText={touched.uf && errors.uf}
                  label="UF"
                  margin="normal"
                  name="uf"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.uf}
                  variant="outlined"
                  disabled={!notFound}
                />
              </div>
            </form>
          </main>

          <footer>{buttons(handleSubmit)}</footer>
        </>
      )}
    </Formik>
  );
};

export default StepEndereco;
