import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const ValueSelector = ({ label, value, onChange, name, choices }) => {
    return (
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {label}
            </FormLabel>
            <RadioGroup
              aria-label={name}
              name={name}
              value={value}
              onChange={onChange}
            >
            <Grid
              container
              spacing={0}
              columuns={3}
            >
            {choices instanceof Array ?
            choices.map((choice, index) => (
              <Grid key={index} lg={4} sm={8} item>
              <FormControlLabel
              value={choice}
              key={index}
              control={<Radio key={index}  />}
              label={choice}
            /> 
            </Grid>
            ))
            :  Object.entries(choices).map(([_value, label]) => (
            <Grid item key={_value}>
              <FormControlLabel
                  value={_value}
                  key={_value}
                  control={<Radio key={_value}  />}
                  label={<span><strong>{_value}</strong> - {label}</span>}
              />
            </Grid>
            ))}
              </Grid>
            </RadioGroup>
          </FormControl>
        </div>)
}

export default ValueSelector;